const SelectVideoBgImage = ({
  imagePath,
  setImagePath,
  setIsBgBlur,
}: {
  imagePath: string
  setImagePath: (val: string) => void
  setIsBgBlur: (val: boolean) => void
}) => {
  const data = ['/office.jpg', '/office1.avif', '/office2.jpg']
  const handlePath = (path: string) => {
    if (path === imagePath) {
      setImagePath('')
    } else {
      setImagePath(path)
      setIsBgBlur(false)
    }
  }

  return (
    <div className='d-flex flex-column'>
      <label className='form-label mb-2 fw-bold'>Image as a video Background</label>
      <div className='d-flex flex-column'>
        <p className='mb-2 mr-2'>Choose the image to apply as a video background visual effect</p>
        <div className='d-flex gap-2 flex-items-center mb-3'>
          {data.map((item, index) => (
            <div
              key={index}
              onClick={() => handlePath(item)}
              className='cursor-pointer'
              style={
                imagePath === item
                  ? {
                      width: '100px',
                      height: '75px',
                      borderRadius: '12px',
                      overflow: 'hidden',
                      border: '2px solid #ff00ae',
                    }
                  : {
                      width: '100px',
                      height: '75px',
                      borderRadius: '12px',
                      overflow: 'hidden',
                      border: '2px solid transparent',
                    }
              }
            >
              <img className='w-100 h-100' src={item} alt='img' />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default SelectVideoBgImage
