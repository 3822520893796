import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {getAuth, useAuth} from '../modules/auth'
import {Activity} from '../modules/oportunities/Components/Activity'
import AboutUs from '../modules/FooterPages/aboutUs/AboutUs'
import ContactUs from '../modules/FooterPages/contactUs/ContactUs'
import {QueryRequestProvider} from '../modules/oportunities/Components/opportunity-list/core/QueryRequestProvider'
import {QueryResponseProvider} from '../modules/oportunities/Components/opportunity-list/core/QueryResponseProvider'
import {ListViewProvider} from '../modules/oportunities/Components/opportunity-list/core/ListViewProvider'
import SearchPage from '../modules/apps/searchPage/SearchPage'
import Notifications from '../modules/apps/notifications/Notifications'
// import ContactPage from '../modules/apps/contact/ContactContactPage'
// import Contact from '../modules/apps/contact/Contact'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const OpportunitiesPage = lazy(() => import('../modules/oportunities/Opportunities'))
  const ContactPage = lazy(() => import('../modules/apps/contact/Contact'))
  const ThemePage = lazy(() => import('../modules/apps/theme/Theme'))

  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const VideoMessagePage = lazy(() => import('../modules/apps/video-messages/VideoMessagePage'))
  const EmailPage = lazy(() => import('../modules/apps/email-received-open/Email'))

  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage')) 
  const {currentUser} = useAuth()
  const auth: any = getAuth()
  console.log('auth:', auth)
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route
          path='auth/*'
          element={
            <Navigate
              to={
                auth?.role == 'super-admin' &&
                (auth?.allowFreeAccess == undefined || auth?.allowFreeAccess == false) &&
                auth?.lastLoginTime == undefined
                  ? '/account/your-plans'
                  : '/dashboard'
              }
              // to={
              //   auth?.city === undefined && auth?.country === undefined
              //     ? '/account/settings'
              //     : '/dashboard'
              // }
            />
          }
        />
        {/* Pages */}
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        <Route
          path='dashboard'
          element={
            <QueryRequestProvider>
              <QueryResponseProvider>
                <ListViewProvider>
                  <Activity />
                </ListViewProvider>
              </QueryResponseProvider>
            </QueryRequestProvider>
          }
        />

        <Route path='builder' element={<BuilderPageWrapper />} />

        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='email/*'
          element={
            <SuspensedView>
              <EmailPage />
            </SuspensedView>
          }
        />
        <Route
          path='contact/*'
          element={
            <SuspensedView>
              <ContactPage />
            </SuspensedView>
          }
        />
        <Route
          path='themes'
          element={
            <SuspensedView>
              <ThemePage />
            </SuspensedView>
          }
        />
        <Route
          path='/videoMessages/*'
          element={
            <SuspensedView>
              <VideoMessagePage />
            </SuspensedView>
          }
        />
        <Route
          path='pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='opportunities/*'
          element={
            <SuspensedView>
              <OpportunitiesPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='crafted/opportunities/*'
          element={
            <SuspensedView>
              <OpportunitiesPage />
            </SuspensedView>
          }
        /> */}

        <Route
          path='/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='about-us'
          element={
            <SuspensedView>
              <AboutUs />
            </SuspensedView>
          }
        />
        <Route
          path='contact-us'
          element={
            <SuspensedView>
              <ContactUs />
            </SuspensedView>
          }
        />
        <Route
          path='search/*'
          element={
            <SuspensedView>
              <SearchPage />
            </SuspensedView>
          }
        />
        <Route
          path='notifications/*'
          element={
            <SuspensedView>
              <Notifications />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
