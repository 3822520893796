import axios from 'axios'
import {ID} from '../../../../../../../_metronic/helpers'
import {User} from './_models'
import firebase from 'firebase/app'
import moment from 'moment'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`

// ===========GET THEMES============
const getThemes: any = async (query: string) => {
  console.log(query, 'queryquery')
  try {
    const currentUser = firebase.auth().currentUser
    const contactsSnapshot = await firebase
      .firestore()
      .collection('themes')
      .where('createdById', '==', currentUser?.uid)
      .get()

    const contactsData: any = contactsSnapshot?.docs?.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))

    let filteredData = contactsData
    filteredData.sort((a: any, b: any) => b.createdAt - a.createdAt)

    if (query.includes('&search=') && !query.includes('&filter_role=')) {
      const searchTerm = query.split('&search=')[1].toLowerCase()
      filteredData = filteredData.filter((data: any) =>
        data.title.toLowerCase().includes(searchTerm)
      )
    }

    if (query.includes('&filter_role=') && !query.includes('&search=')) {
      const filterTerm = query.split('&filter_role=')[1].toLowerCase()
      filteredData = filteredData.filter((data: any) =>
        data.title.toLowerCase().includes(filterTerm)
      )
    }

    if (query.includes('&search=') && query.includes('&filter_role=')) {
      if (query.split('&filter_role=')[1].length > 0) {
        const filterTerm = query.split('&filter_role=')[1].toLowerCase()
        let newFilteredData = filteredData.filter((data: any) =>
          data.title.toLowerCase().includes(filterTerm)
        )
        const dynamicValueString = query.split('&search=')[1]
        const dynamicValue = dynamicValueString.split('&filter_role=')[0].toLowerCase()

        filteredData = newFilteredData.filter((data: any) =>
          data.name.toLowerCase().includes(dynamicValue)
        )
      }
    }
    // HANDLE PAGINATION
    const page = parseInt(query.split('page=')[1].split('&')[0])
    const itemsPerPage = parseInt(query.split('items_per_page=')[1])
    const totalPages = Math.ceil(filteredData.length / itemsPerPage)

    const startIndex = (page - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage

    // GENERATING PAGINATION LINKS ARRAY DYNAMICALLY
    const paginatedData = filteredData.slice(startIndex, endIndex)
    const links = []
    if (totalPages > 0) {
      // PREVIOUS BUTTON
      links.push({
        url: page > 1 ? `/?page=${page - 1}` : null,
        label: '&laquo; Previous',
        active: false,
        page: page > 1 ? page - 1 : null,
      })

      // PAGE NUMBERS
      for (let i = 1; i <= totalPages; i++) {
        const url = `/?page=${i}`
        const label = i.toString()
        const active = i === page
        links.push({url, label, active, page: i})
      }

      // NEXT BUTTON
      links.push({
        url: page < totalPages ? `/?page=${page + 1}` : null,
        label: 'Next &raquo;',
        active: false,
        page: page < totalPages ? page + 1 : null,
      })
    }

    return {
      data: paginatedData,
      payload: {
        pagination: {
          first_page_url: `/?page=1`,
          from: startIndex + 1,
          items_per_page: itemsPerPage,
          last_page: totalPages,
          links,
          next_page_url: page < totalPages ? `/?page=${page + 1}` : null,
          page,
          prev_page_url: page > 1 ? `/?page=${page - 1}` : null,
          to: endIndex,
          total: filteredData.length,
        },
      },
    }
  } catch (error) {
    console.error('Error getting contacts: ', error)
  }
}

// ===========GET ALL THEMES============
const getAllThemes = async () => {
  try {
    const currentUser = firebase.auth().currentUser
    const themesSnapshot = await firebase
      .firestore()
      .collection('themes')
      .where('createdById', '==', currentUser?.uid)
      .get()

    const themesData: any = themesSnapshot?.docs?.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))
    console.log('themeData', themesData)
    return themesData
  } catch (error) {
    console.log(error)
  }
}
// ===========GET SINGLE THEME============
const getThemeById: any = async (id: ID) => {
  try {
    const themeSnapshot = await firebase.firestore().collection('themes').doc(id).get()

    if (!themeSnapshot.exists) {
      throw new Error('Theme not found')
    }

    const themeData = {
      id: themeSnapshot.id,
      ...themeSnapshot.data(),
    }
    return themeData
  } catch (error) {
    console.error('Error getting theme: ', error)
  }
}

// ===========CREATE THEME============
const createTheme = async (userInfo: User): Promise<string | void> => {
  try {
    const currentUser = firebase.auth().currentUser
    if (!currentUser) {
      throw new Error('No authenticated user found')
    }

    const newContactRef = await firebase.firestore().collection('themes').add({
      title: userInfo.themeTitle,
      headerColor: userInfo.headerBarColor,
      bodyColor: userInfo.bodyColor,
      welcomeText: userInfo.welcomeText,
      footerText: userInfo.footerText,
      createdAt: firebase.firestore.Timestamp.now(),
      createdById: currentUser.uid,
    })
    return newContactRef.id
  } catch (error) {
    console.error('Error adding user: ', error)
  }
}

// ===========UPDATE THEME============
const updateTheme = async (userInfo: User): Promise<string | void> => {
  try {
    const contactRef = firebase.firestore().collection('themes').doc(userInfo.id)
    await contactRef.update({
      title: userInfo.themeTitle,
      headerColor: userInfo.headerBarColor,
      bodyColor: userInfo.bodyColor,
      welcomeText: userInfo.welcomeText,
      footerText: userInfo.footerText,
      updatedAt: firebase.firestore.Timestamp.now(),
    })
  } catch (error) {
    console.error('Error updating contact: ', error)
  }
}

// ===========DELETE CONTACT============
// const deleteUser = (userId: ID): Promise<void> => {
//   return axios.delete(`${USER_URL}/${userId}`).then(() => {})
// }
const deleteTheme = async (documentId: ID) => {
  try {
    await firebase.firestore().collection('themes').doc(documentId).delete()
    console.log('Document deleted successfully!')
  } catch (error) {
    console.error('Error deleting document: ', error)
  }
}

const deleteSelectedContacts = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getContactStatsAndVideos = async (id: ID, uid: ID) => {
  try {
    let query = await firebase
      .firestore()
      .collection('recordedVideos')
      .where('sendById', '==', uid)
      .where('sendTo', '==', id)
      .orderBy('createdAt', 'desc')
      .get()

    let tempStats = {
      messagesSent: 0,
      isOpened: 0,
      notOpened: 0,
      viewed: 0,
      interested: 0,
      notInterested: 0,
    }
    let tempVideoMessages = []

    tempStats = {...tempStats, messagesSent: query.size}

    for (let doc of query.docs) {
      let {isOpened, fullyWatched, recipientReaction} = doc.data()
      if (isOpened) {
        tempStats = {...tempStats, isOpened: tempStats.isOpened + 1}
      }
      if (!isOpened) {
        tempStats = {...tempStats, notOpened: tempStats.notOpened + 1}
      }
      if (fullyWatched) {
        tempStats = {...tempStats, viewed: tempStats.viewed + 1}
      }
      if (recipientReaction === 'not-interested') {
        tempStats = {...tempStats, notInterested: tempStats.notInterested + 1}
      }
      if (recipientReaction !== 'not-interested' && recipientReaction !== '') {
        tempStats = {...tempStats, interested: tempStats.interested + 1}
      }

      tempVideoMessages.push({id: doc.id, ...doc.data()})
    }

    return {
      tempStats,
      tempVideoMessages,
    }
  } catch (error: any) {
    alert(error?.message)
  }
}

const getContactStatsAndVideosInRange = async (id: ID, uid: ID, startDate: Date, endDate: Date) => {
  try {
    console.log('Stat', startDate, endDate)
    // const startingDate = moment(startDate).toDate()
    // const endingDate = moment(endDate).toDate()
    const startingDate = moment(startDate).toDate()
    const endingDate = moment(endDate).add(1, 'days').toDate()

    console.log('starting point', startingDate)
    console.log('ending point', endingDate)

    let query = await firebase
      .firestore()
      .collection('recordedVideos')
      .where('sendById', '==', uid)
      .where('sendTo', '==', id)
      .where('createdAt', '>=', firebase.firestore.Timestamp.fromDate(startingDate))
      .where('createdAt', '<', firebase.firestore.Timestamp.fromDate(endingDate))
      .orderBy('createdAt', 'desc')
      .get()

    let tempStats = {
      messagesSent: 0,
      isOpened: 0,
      notOpened: 0,
      viewed: 0,
      interested: 0,
      notInterested: 0,
    }
    let tempVideoMessages = []

    tempStats = {...tempStats, messagesSent: query.size}

    for (let doc of query.docs) {
      let {isOpened, fullyWatched, recipientReaction} = doc.data()
      console.log('recipientReaction', recipientReaction)
      if (isOpened) {
        tempStats = {...tempStats, isOpened: tempStats.isOpened + 1}
      } else {
        tempStats = {...tempStats, notOpened: tempStats.notOpened + 1}
      }
      if (fullyWatched) {
        tempStats = {...tempStats, viewed: tempStats.viewed + 1}
      }
      if (recipientReaction === 'not-interested') {
        tempStats = {...tempStats, notInterested: tempStats.notInterested + 1}
      }
      if (recipientReaction !== 'not-interested' && recipientReaction !== '') {
        tempStats = {...tempStats, interested: tempStats.interested + 1}
      }

      tempVideoMessages.push({id: doc.id, ...doc.data()})
    }

    return {
      tempStats,
      tempVideoMessages,
    }
  } catch (error: any) {
    return
  }
}

export {
  getThemes,
  deleteTheme,
  deleteSelectedContacts,
  getThemeById,
  createTheme,
  updateTheme,
  getContactStatsAndVideos,
  getContactStatsAndVideosInRange,
  getAllThemes,
}
